<template>
  <div class="header adaptive-menu" id="text">
    <div class="header icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 28 26" fill="none">
        <circle cx="14.0019" cy="11.2502" r="4.88372" fill="white"/>
        <path d="M4.67606 1.92606C4.35819 1.60819 3.84084 1.60668 3.54198 1.94248C1.88713 3.80184 0.754487 6.07302 0.267934 8.52413C-0.271557 11.2419 0.00754437 14.0587 1.0699 16.6177C2.13225 19.1768 3.93008 21.3631 6.23574 22.8997C8.54139 24.4364 11.2512 25.2543 14.022 25.25C16.7928 25.2456 19.5 24.4192 21.8008 22.8753C24.1016 21.3314 25.8926 19.1395 26.9469 16.5771C28.0012 14.0147 28.2715 11.1971 27.7234 8.481C27.2292 6.03144 26.0894 3.76383 24.4287 1.90967C24.1288 1.57481 23.6115 1.57794 23.2946 1.89681L20.9882 4.21779C20.6713 4.53665 20.6761 5.04985 20.9654 5.39394C21.9437 6.55757 22.6178 7.95147 22.9202 9.45015C23.2764 11.2156 23.1008 13.047 22.4155 14.7126C21.7302 16.3782 20.5661 17.8029 19.0705 18.8064C17.575 19.81 15.8153 20.3472 14.0143 20.35C12.2133 20.3528 10.4519 19.8212 8.95323 18.8223C7.45455 17.8235 6.28596 16.4024 5.59543 14.739C4.9049 13.0756 4.72349 11.2447 5.07416 9.47818C5.37184 7.97856 6.04159 6.58255 7.01625 5.41585C7.30445 5.07086 7.30764 4.55765 6.98977 4.23978L4.67606 1.92606Z" fill="white"/>
      </svg>
      <h2>DEXIO</h2>
    </div>
    <NavigationHeader />
  </div>

  <div class="header-text-headline">
    <div>
      <h1 style="text-transform: uppercase; align-self: flex-start;" class="h1-h">Dexio Games</h1>
      <p class="t-1">
        More than games. It's a lifestyle. We make apps that not only entertain you or meet your daily needs but also enrich your life experience and personal growth. From quizzes to productivity and utility.
      </p>
    </div>
    <ButtonApp @action="goToForm()" :btnText="'Contact us'"/>
  </div>

  <div class="header text-block">
    <h2>Dexio Games stands as inventive mobile apps studio focused on transforming ideas into standout apps.</h2>
    <p class="t-1">
      As a rapidly expanding company, we’re constantly involved in thrilling projects while utilizing cutting-edge technologies and equipment. We’re more than just developers; we’re your digital world partners.
    </p>
  </div>
</template>

<script setup>
  import NavigationHeader from './for_header/NavigationHeader.vue';
  import ButtonApp from './ButtonApp.vue';
  import smoothscroll from 'smoothscroll-polyfill';
import { onMounted } from 'vue';

  smoothscroll.polyfill();

  let elementForm = null;

  onMounted(() => {
    elementForm = document.querySelector('#footer-form-main');

    return elementForm;
  })


  function goToForm() {
    elementForm.scrollIntoView({behavior: 'smooth'});
  }



  window.addEventListener('scroll', () => {
    if (window.scrollY >= 930) {
      let fixedMenu = document.getElementById('text');
      fixedMenu.style.background = 'black';
    } else if (window.scrollY < 930) {
      let fixedMenu = document.getElementById('text');
      fixedMenu.style.background = '';
    }
  })
</script>

<style lang="scss">
  @import '@/style/mixins.scss';

  .header.adaptive-menu {
    position: fixed;
    padding: 10px 16px;
    width: 100%;

    @include flex-row;
    justify-content: space-between;

    transition: all 0.5s ease;
    box-sizing: border-box;
    z-index: 2;
    .header.icon {
      @include flex-row;
      gap: 8px;
    }
  }

  .header-text-headline {
    padding: 180px 0px 100px 0px;
    @include flex-column;
    align-items: flex-start;
    gap: 40px;

    box-sizing: border-box;
    >div {
      @include flex-column;
      gap: 20px;

      text-align: left;
      h1 {
        align-self: flex-start;
      }
      p.t-1 {
        align-self: center
      }
    }
  }

  .header.text-block {
    @include flex-column;
    gap: 20px;
  }

</style>
<template>
  <div class="projects-list-item" v-for="item in our_projects_data" :key="item.id">
    <picture>
      <source :srcset="item.img[1440]" media="(min-width: 1440px)" width="460px" height="560px">
      <source :srcset="item.img[768]" media="(min-width: 768px)" width="728px" height="560px">
      <source :srcset="item.img[428]" media="(min-width: 428px)" width="396px" height="466px">
      <img loading="lazy" decoding="async" :src="item.img[320]" :alt="item.h" width="288px" height="350px">
    </picture>

    <div class="projects text-icon-button">
      <div class="projects text-icon">
        <img class="projects-icon" loading="lazy" decoding="async" :src="item.icon" alt="Icon not found">
        <div class="projects text">
          <h3>{{ item.h }}</h3>
          <p class="t-1">{{ item.p }}</p>
        </div>
      </div>
      <ButtonApple @action="goToApp(item.link)" />
    </div>
  </div>
</template>

<script setup>
  import ButtonApple from '@/components/ButtonApple.vue';
  import {our_projects_data} from '@/db';

  function goToApp(link) {
    window.open(link);
  }

</script>

<style lang="scss">
  @import '@/style/mixins.scss';

  .projects-list-item {
    @include flex-column;
    gap: 30px;
  }

  .projects.text-icon-button {
    @include flex-column;
    gap: 30px;
    .projects.text-icon {
      @include flex-column;
      gap: 20px;
      .projects.text {
        @include flex-column;
        align-items: flex-start;
        gap: 15px;
      }
    }
  }
</style>
<template>
  <form>
    <ModalSuccess @closeModal="closeModal" v-if="statusModal"/>
    <div class="form-fields">
      <div>

        <h2 style="color: white;">Get in touch</h2>
        <div>
          <div class="field-width-error-message" style="width: 100%;">
            <input ref="fieldOfName" class="t-1" placeholder="Your name" type="text" @blur="showErrorName()">
            <p ref="errorName" class="error"></p>
          </div>
          <div class="field-width-error-message" style="width: 100%;">
            <input ref="fieldOfEmail" class="t-1" placeholder="Your Email" type="email" @blur="showErrorEmail()">
            <p ref="errorEmail" class="error"></p>
          </div>
        </div>
      </div>
    <textarea ref="fieldOfMessage" placeholder="Your message" class="t-1" name="" cols="30" rows="8"></textarea>
  </div>
    <ButtonApp @action="sendEmail" :lockedValue="statusForm" ref="buttonForm" :btnText="'Send Message'" />
  </form>
</template>

<script setup>
  import ButtonApp from '@/components/ButtonApp.vue';
  import ModalSuccess from './ModalSuccess.vue';
  import { ref} from 'vue';
  import axios from 'axios';


  let fieldOfName = ref("");
  let fieldOfEmail = ref("");
  let fieldOfMessage = ref("");
  let errorName = ref("");
  let errorEmail = ref("");
  let statusForm = ref(false);
  let statusModal = ref(false);
  const regExpEmail = /^([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z_-]+)$/;
  // let move = document.getElementById('footer-form-main');

  function closeModal() {
    // move.scrollIntoView({behavior: 'smooth'})
    
    statusModal.value = !statusModal.value;

    if (statusModal.value) {
      document.body.style.overflow = 'hidden';
    } else if (!statusModal.value) {
      document.body.style.overflow = '';
    }
  }

  function showErrorName() {
    if (!fieldOfName.value.value) {
      errorName.value.textContent = 'You need to enter an name';
      fieldOfName.value.classList.add('error-field');
      statusForm.value = true;
      return 0;
    } else {
      errorName.value.textContent = '';
      fieldOfName.value.classList.remove('error-field');
      statusForm.value = false;
    }
  }

  function showErrorEmail() {
    if (!fieldOfEmail.value.value) {
      errorEmail.value.textContent = 'You need to enter an email';
      fieldOfEmail.value.classList.add('error-field');
      statusForm.value = true;
      return 0;
    } else if (!regExpEmail.test(fieldOfEmail.value.value)) {
      errorEmail.value.textContent = 'Entered value needs to be an e-mail address';
      fieldOfEmail.value.classList.add('error-field');
      statusForm.value = true;
      return 0;
    } else {
      errorEmail.value.textContent = '';
      fieldOfEmail.value.classList.remove('error-field');
      statusForm.value = false;
    }
  }

  async function sendEmail() {
    showErrorName();
    showErrorEmail();

    if (showErrorEmail() == 0 || showErrorName() == 0) {
      return 0;
    }

    try {
      const payload = {
        name: fieldOfName.value.value,
        email: fieldOfEmail.value.value,
        message: fieldOfMessage.value.value,
      };
      await axios.post(
        "https://webform.dkotrack.com/api/new/dexio-games.com",
        payload
      );
    } catch (error) {
      console.error(error?.response?.data || error?.message || error);
    } finally {
      console.info("end send mail");
      closeModal();
    }
  }

</script>

<style lang="scss">
  @import '@/style/mixins.scss';

  form {
    width: 100%;
    @include flex-column;
    gap: 100px;

    .form-fields {
      width: 100%;
      @include flex-column;
      gap: 15px;
      >div:nth-child(1) {
        width: 100%;
        @include flex-column;
        gap: 15px;
        h2 {
          align-self: flex-start;
        }
        >div {
          @include flex-column;
          gap: 15px;
          width: 100%;
        }
      }
      input, textarea {
        width: 100%;
      }
    }    
  }

  .field-width-error-message {
    @include flex-column;
    gap: 4px;
    .error {
      align-self: flex-start;
    }
  }

  .error-field {
    border: 1px solid rgba(255, 60, 60, 0.50);
  }
  .error-field::placeholder {
    color: #FF3C3C;
  }
</style>
import projects_img_320_locator from '@/assets/for_projects/projects-320.webp';
import projects_img_428_locator from '@/assets/for_projects/projects-428.webp';
import projects_img_768_locator from '@/assets/for_projects/projects-768.webp';
import projects_img_1440_locator from '@/assets/for_projects/projects-1440.webp';
import projects_icon_locator from '@/assets/for_projects/projects-icon-1.webp';

import best_features_icon_1 from '@/assets/for_best_features/icon-1.svg';
import best_features_icon_2 from '@/assets/for_best_features/icon-2.svg';
import best_features_icon_3 from '@/assets/for_best_features/icon-3.svg';
import best_features_icon_4 from '@/assets/for_best_features/icon-4.svg';
import best_features_icon_5 from '@/assets/for_best_features/icon-5.svg';
import best_features_icon_6 from '@/assets/for_best_features/icon-6.svg';

import contacts_icon_1 from '@/assets/for_contacts_menu/icon-1.svg';
import contacts_icon_2 from '@/assets/for_contacts_menu/icon-2.svg';
import contacts_icon_3 from '@/assets/for_contacts_menu/icon-3.svg';

export const navigation_header_data = [
  {
    id: 1,
    text: 'About Us',
    link: 'header-main',
  },
  {
    id: 2,
    text: 'Projects',
    link: 'our-projects-main',
  },
  {
    id: 3,
    text: 'Feature',
    link: 'best-features-main',
  },
  {
    id: 4,
    text: 'Vacancies',
    link: 'our-team-main',
  },
  {
    id: 5,
    text: 'Contact Us',
    link: 'footer-form-main',
  },
]

export const our_projects_data = [
  {
    id: 1,
    img: {
      320: projects_img_320_locator,
      428: projects_img_428_locator,
      768: projects_img_768_locator,
      1440: projects_img_1440_locator,
    },
    icon: projects_icon_locator,
    h: 'Phone Locator',
    p: 'Our location tracker app offers real-time tracking, allowing you to stay connected with your loved ones at all times. With its user-friendly interface and precise location updates, you can ensure the safety and whereabouts of your family and friends. Whether they’re across town or across the globe, keep peace of mind knowing you can locate them with a tap of a button.',
    link: 'https://apps.apple.com/ru/app/phone-locator-360-gps-%D1%82%D1%80%D0%B5%D0%BA%D0%B5%D1%80/id6469478654',
  },
]

export const best_features_data = [
  {
    id: 1,
    icon: best_features_icon_1,
    text: "Exciting Game Mechanics",
  },
  {
    id: 2,
    icon: best_features_icon_2,
    text: "Cross-Platform Compatibility",
  },
  {
    id: 3,
    icon: best_features_icon_3,
    text: "User-Friendly Interface",
  },
  {
    id: 4,
    icon: best_features_icon_4,
    text: "High-Quality Graphics",
  },
  {
    id: 5,
    icon: best_features_icon_5,
    text: "Regular Updates",
  },
  {
    id: 6,
    icon: best_features_icon_6,
    text: "Code Guarantee",
  },
]

export const our_team_data = [
  {
    id: 1,
    h: "Game Designer",
    p: "We are looking for a Game designer who will help us optimize existing projects and launch new and cool products!",
  },
  {
    id: 2,
    h: "Backend Developer",
    p: "We are looking for a skilled backend developer who can integrate into existing development processes. If you are interested, please contact us!",
  },
  {
    id: 3,
    h: "Unity Developer",
    p: "Working in our company is not a dry bureaucracy, but a creative process, where each team member influences the final product.",
  },
]

export const contacts_menu_data = [
  {
    id: 1,
    icon: contacts_icon_1,
    text: '+905305611336',
  },
  {
    id: 2,
    icon: contacts_icon_2,
    text: 'dex@dexio-games.com',
  },
  {
    id: 3,
    icon: contacts_icon_3,
    text: '34418 Istanbul yeşilce man. dalgıç sk. dome no: 3/5 iç kapi no: 1 kağıthane',
  },
]

<template>
  <div class="navigation-header-main">
    <div @click="getMenu" ref="burgerBlock" class="burger-block">
      <div ref="line1" class="burger-line line1"></div>
      <div ref="line2" class="burger-line line2"></div>
      <div ref="line3" class="burger-line line3"></div>
    </div>

    <nav class="header links-list" ref="linksList">
      <h3 class="link-item" @click="goToElement(item.link)" v-for="item in navigation_header_data" :key="item.id">{{ item.text }}</h3>
    </nav>
  </div>
</template>

<script setup>
  import { navigation_header_data } from '@/db';
  import { ref } from 'vue';
  import smoothscroll from 'smoothscroll-polyfill';

  smoothscroll.polyfill();

  let line1 = ref(""),
      line2 = ref(""),
      line3 = ref(""),
      burgerBlock = ref(""),
      linksList = ref("");

  function goToElement(link) {
    let anchor = document.getElementById(link);

    anchor.scrollIntoView({behavior: 'smooth'});
    if (linksList.value.classList.contains('show')) {
      getMenu();
    }
  }

  function getMenu() {

    if (!linksList.value.classList.contains('show')) {
      burgerBlock.value.classList.add('move-block');
      line1.value.classList.add('move-line1');
      line2.value.classList.add('move-line2');
      line3.value.classList.add('move-line3');
      linksList.value.classList.add('show');

      document.body.style.overflow = 'hidden';
    } else {
      burgerBlock.value.classList.remove('move-block');
      line1.value.classList.remove('move-line1');
      line2.value.classList.remove('move-line2');
      line3.value.classList.remove('move-line3');
      linksList.value.classList.remove('show');

      document.body.style.overflow = '';
    }
  }
</script>

<style lang="scss">
  @import '@/style/mixins.scss';

  .navigation-header-main {
    @include flex-row;
  }

  .burger-block {
    cursor: pointer;
    width: 40px;
    height: 40px;

    @include flex-column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    box-sizing: border-box;

    .burger-line {
      width: 36px;
      height: 2px;
      transition: all 0.5s ease;
      background: white;
    }
  }

  .header.links-list {
    display: none;
    left: -100%;
    transition: all 1s ease;
  }

  @media (min-width: 1440px) {
    .burger-block {
      display: none;
    }
    .header.links-list {
      @include flex-row;
      gap: 40px;
    }
  }


  .move-block {
    gap: 0px;
    align-items: center;
    z-index: 4;
  }
  .move-line1 {
    transform: rotate(45deg);
  }
  .move-line2 {
    display: none;
  }
  .move-line3 {
    transform: rotate(-45deg);
  }
  .show.header.links-list {
    padding: 100px 0px 0px 0px;
    display: flex;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0;
    box-sizing: border-box;
    @include flex-column;
    gap: 40px;
    background: center/cover url('@/assets/header-back-2.webp');
    z-index: 3;
  }
</style>